@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-display: swap;
  font-family: "thin";
  src: url("./assets/font/Roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "regular";
  src: url("./assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "bold";
  src: url("./assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-display: swap;
  box-sizing: border-box;
}
@media (max-width: 992px) {
  * {
    font-size: 16px;
    line-height: 22px;
  }
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.Toastify * {
  font-size: 18px;
}
